<template>
  <v-container v-if="$store.state.auth.user">
    <div class="text-h5 my-5">Hallo, {{ $store.getters.getFullName }}</div>
    <Representative></Representative>
  </v-container>
  <v-container v-else class="pa-9">
    <v-card>
      <v-card-title>
        Bitte melden Sie sich mit Ihrem Mitarbeiterkonto an.
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import Representative from "../components/Representative.vue";

export default {
  components: {
    Representative,
  },

  data() {
    return {};
  },

  watch: {
    "$store.state.auth.user": {
      handler: function (user) {
        if (user) {
          this.$store
            .dispatch(
              "attachUserPropertiesOnSnapshot",
              this.$store.state.auth.profile.employee.aid.toLowerCase()
            )
            .then(() => {
              this.$store
                .dispatch(
                  "attachAllUsersOnSnapshot",
                  this.$store.state.user.userProperties.persid
                )
                .then(() => {
                  this.$store.dispatch("attachAppsOnSnapshot");
                  this.$store
                    .dispatch(
                      "attachRepresentativesOnSnapshot",
                      this.$store.state.user.userProperties.persid
                    )
                    .then(() => {
                      this.$store.dispatch(
                        "createDefaultRepresentative",
                        this.$store.state.user.userProperties.persid
                      );
                    });
                });
            });

          // this.$store.dispatch(
          //   "attachRepresentativesOnSnapshot",
          //   this.$store.state.auth.profile.employee.aid.toLowerCase()
          // );

          // this.$store.dispatch(
          //   "attachAllUsersOnSnapshot",
          //   this.$store.state.auth.profile.employee.aid.toLowerCase()
          // );

          // this.$store.dispatch("attachAppsOnSnapshot").then(() => {
          //   console.log(this.$store.getters.allowedAppsTable);
          // });
        }
      },
      immediate: true,
    },
  },
};
</script>
