const state = {
    error: false,
    errorMessage: ''
}

const mutations = {
    setError(state, { error, errorMessage }) {
        state.error = error
        state.errorMessage = errorMessage
    }
}

export default { state, mutations }
