import { firestore } from '../../firebase'

const state = {
    apps: [],
    firestoreListener: null,
    isLoading: false
}

const getters = {
    allowedApps(state, _, rootState) {
        return state.apps.filter(app => {
            if (app.allowedUsers) {
                return app.allowedUsers.includes(rootState.user.userProperties.persid)
            } else {
                return true
            }
        })
    },

    allowedAppsTable(_, getters, rootState) {
        return getters.allowedApps.sort((a, b) => {
            const systemA = a.system.toUpperCase();
            const systemB = b.system.toUpperCase();
            if (systemA < systemB) {
                return -1;
            }
            if (systemA > systemB) {
                return 1;
            }
            return 0;
        }).map(app => {
            let representativeId = null
            // let representativeName = ''
            let fromDate = ''
            let toDate = ''
            let isActive = false
            let representativeEntryId = ''
            // let isDefaultRepresentative = false
            const representative = rootState.representatives.representatives.find(entry => entry.appId == app.id)
            if (representative) {
                // fromDate = new Date(representative.fromDate.toDate() - new Date().getTimezoneOffset() * 60000)
                // .toISOString()
                // .substring(0, 10)
                fromDate = representative.fromDate != "" ? representative.fromDate.toDate() : ''
                // toDate = new Date(representative.toDate.toDate() - new Date().getTimezoneOffset() * 60000)
                //     .toISOString()
                // .substring(0, 10)
                toDate = representative.toDate != '' ? representative.toDate.toDate() : ''
                isActive = representative.isActive
                representativeId = representative.representativeId
                representativeEntryId = representative.id
                // isDefaultRepresentative = representative.isDefaultRepresentative
            }

            return {
                appId: app.id,
                system: app.system,
                process: app.process,
                fromDate: app.advice ? '' : fromDate,
                toDate: app.advice ? '' : toDate,
                isActive: app.advice ? false : isActive,
                direction: app.direction,
                advice: app.advice ? app.advice : '',
                representativeId: representativeId ? representativeId : '',
                representativeEntryId: representativeEntryId ? representativeEntryId : '',
                // isDefaultRepresentative: isDefaultRepresentative
            }
        })
    },

    notManualApps(_, getters) {
        const filteredApps = getters.allowedAppsTable.filter(app => app.direction != 'manuell')
        if (filteredApps.length > 0) {
            return filteredApps
        } else {
            return []
        }
    }
}

const actions = {
    attachAppsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.apps)
                return
            }

            state.isLoading = true
            state.firestoreListener = firestore
                .collection("apps")
                .onSnapshot(docs => {
                    const firebaseApps = [];
                    docs.forEach((doc) => {
                        firebaseApps.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    })
                    state.apps = firebaseApps
                    state.isLoading = false
                    resolve(state.apps)
                }, (error) => {
                    console.warn(error.message)
                    reject(error)
                })
        })
    },

    detachAppsOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    },
}

export default { state, getters, actions }
