import { firestore } from '../../firebase'

const state = {
    representatives: [],
    isLoading: true,
    firestoreListener: null
}

const getters = {
    defaultRepresentativeObject(state) {
        return state.representatives.find(rep => rep.appId == 'all')
    },

    isDefaultRepresentativeObjectActive(state) {
        if (state.representatives.length > 0) {
            return state.representatives.find(rep => rep.appId == 'all').isActive
        }
        return false
    }
}

const actions = {
    attachRepresentativesOnSnapshot({ state }, userId) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.representatives)
                return
            }
            state.isLoading = true
            state.firestoreListener = firestore
                .collection("representatives")
                .where("uid", '==', userId)
                .onSnapshot(async docs => {

                    const firebaseRepresentatives = [];
                    docs.forEach((doc) => {
                        firebaseRepresentatives.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    })
                    state.representatives = firebaseRepresentatives
                    state.isLoading = false
                    resolve(state.representatives)
                }, (error) => {
                    console.warn(error.message)
                    reject(error)
                })
        })
    },

    detachRepresentativesOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    },

    createDefaultRepresentative({ rootGetters }, userId) {
        if (rootGetters.notManualApps.length > 0) {
            firestore
                .collection('representatives')
                .where('uid', '==', userId)
                .where('appId', '==', 'all')
                .get()
                .then(snapshot => {
                    if (snapshot.docs.length == 0) {
                        const docRef = firestore.collection('representatives').doc()
                        docRef.set({
                            appId: 'all',
                            uid: userId,
                            isActive: false,
                            fromDate: '',
                            toDate: '',
                            representativeId: '',
                            system: 'Alle Systeme',
                            process: 'Alle Prozesse',
                            representativeEntryId: docRef.id
                        }, { merge: true })
                    }
                })
        }
    }
}

export default { state, getters, actions }
