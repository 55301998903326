import Vue from "vue"

const state = {
    isAuthenticating: true,
    user: null,
    profile: {}
}

const mutations = {
    setIsAuthenticating(state, isAuthenticating) {
        state.isAuthenticating = isAuthenticating
    },

    setUserLogin(state, user) {
        state.user = user
    },

    setProfile(state, profile) {
        Vue.set(state, "profile", profile)
    },

    setUserLogout(state) {
        state.user = null
        state.profile = null
    }
}

export default { state, mutations }
