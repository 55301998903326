import { firestore } from '../../firebase'

const state = {
    userProperties: {},
    firestoreListener: null,
    isLoading: false
}

const getters = {
    getFullName(state) {
        return `${state.userProperties.firstname} ${state.userProperties.name}`
    }
}

const actions = {
    attachUserPropertiesOnSnapshot({ state }, userId) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.userProperties)
                return
            }
            state.isLoading = true
            state.firestoreListener = firestore
                .collection('users')
                .where('adaccount', '==', userId)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const firebaseUserProperties = { ...doc.data(), id: doc.id }
                        state.userProperties = firebaseUserProperties
                        state.isLoading = false
                        resolve(state.userProperties)
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        })
    },

    detachUserOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    },
}

export default { state, getters, actions }
