import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import user from './modules/user'
import users from './modules/users'
import apps from './modules/apps'
import representatives from './modules/representatives'
import error from './modules/error'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth, user, apps, users, representatives, error
    }
})
